import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeading } from "components/misc/Headings";
import SocialIcon from "components/SocialIcon";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Refund Policy" }) => {
  return (
    <AnimationRevealPage>
      <SocialIcon />
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Thank you for choosing <strong>[Your E-Commerce Service]</strong> for booking your accommodation for Confero 2024. Please review our refund policy below:</p><br/>

            <ul type="1">

                <li><strong>Non-Refundable Transactions:</strong> All bookings made through our website are considered <strong>final and non-refundable</strong>. Once a booking order is placed and payment is processed, it cannot be cancelled or refunded.</li>

                <li><strong>Room Descriptions</strong>: We make every effort to provide accurate and detailed Room descriptions to help you make informed booking decisions. If you have any questions or concerns, please contact our Confero Accommodation Team at <span style={{color:"blue"}}>hotels@confero3240.in / 9957181036</span> before making a booking.</li>

                <li><strong>Unauthorized Transactions</strong>: If you suspect unauthorized use of your payment information, please contact our customer support immediately for assistance.</li>

                <li><strong>Policy Changes</strong>: We reserve the right to modify or update our refund policy at any time. Any changes will be posted on our website and will be effective immediately upon posting.</li>

            </ul><br/>

            <p>Please make sure to carefully review your booking details before completing your booking. By making a booking on our website, you acknowledge and agree to our non-refund policy.</p>

            <p>If you have any questions or concerns, please feel free to contact our Confero Accommodation Team at <span style={{color:"blue"}}>hotels@confero3240.in / 9957181036</span>.</p>

            <p>Thank you for your understanding.</p>

            <p>Sincerely,</p>
            <a href="www.rid3240.org">www.rid3240.org</a>
            
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

import React, { useState, useEffect } from 'react';
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown } from 'react-bootstrap';

const Navbar = () => {

    const navigate = useNavigate();
    const [refreshed, setRefreshed] = useState(false);

    const handleRefresh = () => {
        window.location.reload();
        setRefreshed(true);
    };

    const handleDistrictCommittee = () => {
        navigate('/district-committee')
        window.location.reload();
        setRefreshed(true);
    }

    const handleDistrictSecretariate = () => {
        navigate('/district_secretariate')
        window.location.reload();
        setRefreshed(true);
    }

    const handleAssistantGovernor = () => {
        navigate('/assistant_governor')
        window.location.reload();
        setRefreshed(true);
    }

    const handleDistrictEvents = () => {
        navigate('/district_events')
        window.location.reload();
        setRefreshed(true);
    }

    const handleClubInAction = () => {
        navigate('/clubs_in_action')
        window.location.reload();
        setRefreshed(true);
    }







    return (
        <>
            <nav className="navbar navbar-expand-lg py-2 mt-5 relative" style={{ paddingLeft: 'auto', paddingRight: 'auto', boxShadow: '0 0 10px #80808085' }}>

                <div className="px-6" style={{}}>

                    {/* <div className="navbar-collapse grow " id="navbarSupportedContentY" style={{}}> */}
                    <div className="navbar-collapse grow " style={{}}>
                        <ul className="navbar-nav mr-auto " style={{ marginLeft: 'auto', fontWeight: 'bold', display: 'flex' }}>

                            <li className="nav-item">
                                <NavLink to={"/"}>
                                    {/* <button type="button" className="inline-block px-6 py-2.5 bg-transparent font-medium text-xs leading-tight uppercase rounded hover:bg-gray-100 focus:focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 active:text-blue-800 transition duration-300 ease-in-out" style={{ fontWeight: 'bold', color: '#4054b2' }}>Homepage</button> */}
                                    <button className="border-0 bg-transparent text-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                        Homepage
                                    </button>
                                </NavLink>
                            </li>

                            <button id="multiLevelDropdownButton"
                                data-dropdown-toggle="dropdown"
                                className="border-0 bg-transparent text-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                type="button"
                            //  onClick={handleRefresh}
                            >
                                District

                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="caret-down"
                                    className="w-2"
                                    role="img"
                                    xmlns="https://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                    style={{ marginLeft: '15px' }}
                                >
                                    <path
                                        fill="currentColor"
                                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                                    ></path>
                                </svg>
                            </button>
                            <div id="dropdown" className="w-auto z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="multiLevelDropdownButton">
                                    <li>
                                        {/* <NavLink
                                            to={"/district-committee"}
                                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                            District Committee
                                        </NavLink> */}
                                        <div
                                            // onClick={() => navigate('/district-committee')}
                                            onClick={handleDistrictCommittee}
                                            className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                            District Committee
                                        </div>
                                    </li>

                                    <li>
                                        {/* <NavLink
                                            to={"/district_secretariate"}
                                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                        >
                                            District Secretariate and Administration
                                        </NavLink> */}
                                        <div
                                            onClick={handleDistrictSecretariate}
                                            className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                        >
                                            District Secretariate and Administration
                                        </div>
                                    </li>

                                    <li>
                                        {/* <NavLink to={"/assistant_governor"}
                                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                            Assistant Governor's
                                        </NavLink> */}
                                        <div
                                            onClick={handleAssistantGovernor}
                                            className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                        >
                                            Assistant Governor's
                                        </div>

                                    </li>
                                    <li>
                                        {/* <NavLink
                                            to={"/district_events"}
                                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                            District Events
                                        </NavLink> */}
                                        <div
                                            onClick={handleDistrictEvents}
                                            className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                        >
                                            District Events
                                        </div>
                                    </li>
                                </ul>
                            </div>


                            <button id="multiLevelDropdownButtonarea"


                                data-dropdown-toggle="dropdownarea" className="border-0 bg-transparent text-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                Rotary Areas of Focus

                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="caret-down"
                                    className="w-2"
                                    role="img"
                                    xmlns="https://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                    style={{ marginLeft: '15px' }}
                                >
                                    <path
                                        fill="currentColor"
                                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                                    ></path>
                                </svg>
                            </button>

                            <div id="dropdownarea"
                                // onClick={ window.location.reload() && setRefreshed(true)}
                                className="w-auto z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="multiLevelDropdownButtonarea">
                                    <li>
                                        {/* <NavLink
                                            to={"/"}
                                        > */}
                                            <div
                                                onClick={() => window.location.reload() && navigate('/')}
                                                className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >
                                                Basic Education And Literacy
                                            </div>
                                        {/* </NavLink> */}
                                    </li>

                                    <li>
                                        {/* <NavLink
                                            to={"/"}
                                        > */}

                                            <div
                                                onClick={() => window.location.reload()}
                                                className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >
                                                Disease Prevention And Treatment
                                            </div>
                                        {/* </NavLink> */}
                                    </li>
                                    <li>
                                        {/* <NavLink
                                            to={"/"}
                                        > */}
                                            <div
                                                onClick={() => window.location.reload()}
                                                className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >
                                                Economic Community And Development
                                            </div>
                                        {/* </NavLink> */}
                                    </li>
                                    <li>
                                        {/* <NavLink
                                            to={"/"}
                                        > */}
                                            <div
                                                onClick={() => window.location.reload()}
                                                className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >
                                                Mental And Health Child
                                            </div>
                                        {/* </NavLink> */}
                                    </li>
                                    <li>
                                        {/* <NavLink
                                            to={"/"}
                                        > */}
                                            <div
                                                onClick={() => window.location.reload()}
                                                className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >
                                                Peace And Conflict Prevention/Resolution
                                            </div>
                                        {/* </NavLink> */}
                                    </li>
                                    <li>
                                        {/* <NavLink
                                            to={"/"}
                                        > */}
                                            <div
                                                onClick={() => window.location.reload()}
                                                className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >
                                                Water And Sanitation
                                            </div>
                                        {/* </NavLink> */}
                                    </li>
                                    <li>
                                        {/* <NavLink to={"/"}
                                        > */}
                                            <div
                                                onClick={() => window.location.reload()}
                                                className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >
                                                Supporting The Environment
                                            </div>
                                        {/* </NavLink> */}
                                    </li>
                                </ul>
                            </div>


                            <div>
                                <button id="multiLevelDropdownButtonservice"
                                    // onClick={() =>  window.location.reload()}
                                    data-dropdown-toggle="dropdownservice" className="border-0 bg-transparent text-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                    Partners in Service

                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="caret-down"
                                        className="w-2"
                                        role="img"
                                        xmlns="https://www.w3.org/2000/svg"
                                        viewBox="0 0 320 512"
                                        style={{ marginLeft: '15px' }}
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                                        ></path>
                                    </svg>
                                </button>
                                <div id="dropdownservice" className="w-auto z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="multiLevelDropdownButtonservice">
                                        <li>
                                            <button
                                                // onClick={() =>  window.location.reload()||  setRefreshed(true)}
                                                id="doubleDropdownButton" data-dropdown-toggle="doubleDropdown" data-dropdown-placement="right-start" type="button" className="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                2020-2021
                                                <svg aria-hidden="true" className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="https://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                            </button>
                                            <div id="doubleDropdown" className="w-auto z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="doubleDropdownButton">
                                                    <li>
                                                        <button id="doubleDropdownButtonMenus" data-dropdown-toggle="doubleDropdownmenus" data-dropdown-placement="right-start" type="button" className="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                            Rotaract
                                                            <svg aria-hidden="true" className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="https://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                        </button>
                                                        <div id="doubleDropdownmenus" className=" z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="doubleDropdownButtonMenus">
                                                                <li>
                                                                    {/* <NavLink to={"#"}

                                                                    > */}
                                                                        <div
                                                                            onClick={() => window.location.reload()}
                                                                            className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                                        >
                                                                            Rotaract 3240
                                                                        </div>
                                                                    {/* </NavLink> */}
                                                                </li>
                                                                <li>
                                                                    {/* <NavLink
                                                                        to={"#"}

                                                                    > */}
                                                                        <div
                                                                            onClick={() => window.location.reload()}
                                                                            className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                                        >
                                                                            GC-EC
                                                                        </div>
                                                                    {/* </NavLink> */}
                                                                </li>
                                                                <li>
                                                                    {/* <NavLink
                                                                        to={"#"}
                                                                    > */}
                                                                        <div
                                                                            onClick={() => window.location.reload()}
                                                                            className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                                        >
                                                                            President & Secretary Batch 2020-21 Delegates
                                                                        </div>
                                                                    {/* </NavLink> */}
                                                                </li>
                                                                <li>
                                                                    {/* <NavLink to={"#"}
                                                                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                                    > */}

                                                                        <div
                                                                            onClick={() => window.location.reload()}
                                                                            className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                                        >
                                                                            RDD 2020-21
                                                                        </div>
                                                                    {/* </NavLink> */}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        {/* <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Rotaract</a> */}
                                                    </li>
                                                    <li>
                                                        <button id="doubleDropdownButtonMenusInteract" data-dropdown-toggle="doubleDropdownmenusinteract" data-dropdown-placement="right-start" type="button" className="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                            Interact
                                                            <svg aria-hidden="true" className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="https://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                        </button>
                                                        <div id="doubleDropdownmenusinteract" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="doubleDropdownButtonMenusInteract">
                                                                <li>
                                                                    {/* <NavLink to={"#"}

                                                                    > */}
                                                                        <div
                                                                            onClick={() => window.location.reload()}
                                                                            className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                                        >
                                                                            ICA 3240
                                                                        </div>
                                                                    {/* </NavLink> */}
                                                                </li>
                                                                <li>
                                                                    {/* <NavLink to={"#"}

                                                                    > */}
                                                                        <div
                                                                            onClick={() => window.location.reload()}
                                                                            className=" cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                                        >
                                                                            Interact District Council -3240 2020-21
                                                                        </div>
                                                                    {/* </NavLink> */}
                                                                </li>

                                                            </ul>
                                                        </div>
                                                        {/* <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Interact</a> */}
                                                    </li>

                                                </ul>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            

                            <li className="nav-item">
                                {/* <NavLink to={'/brand_center'}> */}
                                    {/* <button type="button" className="inline-block px-6 py-2.5 bg-transparent text-blue-600 font-medium text-xs leading-tight uppercase rounded hover:bg-gray-100 focus:text-blue-700 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 active:text-blue-800 transition duration-300 ease-in-out" style={{ fontWeight: 'bold', color: '#4054b2' }}>Brand Center</button> */}
                                    <button
                                        onClick={() => window.location.reload() && navigate('/brand_center')}
                                        className="border-0 bg-transparent text-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                        Brand Center
                                    </button>
                                {/* </NavLink> */}
                            </li>

                            
                            <button
                                // onClick={ window.location.reload() && setRefreshed(true)}
                                id="multiLevelDropdownButtonareaclubwebsites" data-dropdown-toggle="dropdownwebsites" className="border-0 bg-transparent text-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                Club Websites

                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="caret-down"
                                    className="w-2"
                                    role="img"
                                    xmlns="https://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                    style={{ marginLeft: '15px' }}
                                >
                                    <path
                                        fill="currentColor"
                                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                                    ></path>
                                </svg>
                            </button>
                            <div id="dropdownwebsites" className="w-auto z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="multiLevelDropdownButtonareaclubwebsites">
                                    <li>
                                        <div
                                            onClick={() => window.location.reload()}
                                            // href="https://rotarybs3240.weebly.com/"
                                            className="cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                            Rotary Club of Bolpur-Santiniketan
                                        </div>
                                    </li>

                                    <li>
                                        <div
                                            onClick={() => window.location.reload()}
                                            // href="https://www.rotarygangtok.org/"
                                            className="cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                            Rotary Club of Gangtok
                                        </div>
                                    </li>
                                    <li>
                                        <div
                                            onClick={() => window.location.reload()}
                                            // href="https://rid3240.org/new/wp-content/uploads/2020/06/ECONOMIC-AND-COMMUNITY-DEVELOPMENT.pdf"
                                            className="cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                            Rotary Club of Guwahati City
                                        </div>
                                    </li>
                                    <li>
                                        {/* <NavLink
                                            to={"#"}> */}
                                            <div
                                                onClick={() => window.location.reload()}

                                                className="cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >
                                                Rotary Club of Malda
                                            </div>
                                        {/* </NavLink> */}
                                    </li>
                                    <li>
                                        {/* <NavLink
                                            to={"#"}
                                        > */}
                                            <div
                                                onClick={() => window.location.reload()}
                                                className="cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >
                                                Rotary Club of Tezpur
                                            </div>
                                        {/* </NavLink> */}
                                    </li>
                                    <li>
                                        <div
                                            onClick={() => window.location.reload()}
                                            // href="https://rid3240.org/new/wp-content/uploads/2020/06/WATER-AND-SANITATION.pdf"
                                            className="cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                            Rotary Club of Siliguri Central
                                        </div>
                                    </li>
                                    <li>
                                        <div
                                            onClick={() => window.location.reload()}
                                            // href="https://ww1.rotaryorchidcityshillong.org/"
                                            className="cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                        >
                                            Rotary Club of Orchid City Shillong
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <li className="nav-item">
                                {/* <NavLink to={"/clubs_in_action"}>
                                    <button className="border-0 bg-transparent text-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                        Clubs in Action
                                    </button>
                                </NavLink> */}

                                <button
                                    className="border-0 bg-transparent text-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    type="button"
                                    onClick={handleClubInAction}
                                >
                                    Clubs in Action
                                </button>

                            </li>

                            {/* <li className="nav-item mb-2 lg:mb-0">
                            <a href="/rotary_year">
                                <button type="button" className="inline-block px-6 py-2.5 bg-transparent text-blue-600 font-medium text-xs leading-tight uppercase rounded hover:bg-gray-100 focus:text-blue-700 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 active:text-blue-800 transition duration-300 ease-in-out" style={{ fontWeight: 'bold', color: '#4054b2' }}>Rotary Year 2023-24</button>
                            </a>
                        </li> */}

                            <li className="nav-item">
                                {/* <NavLink to={"/contact_us"}> */}
                                    <button
                                        onClick={() => window.location.reload() && navigate('/contact_us')}
                                        className="border-0 bg-transparent text-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                        Contact Us
                                    </button>
                                {/* </NavLink> */}
                            </li>

                        </ul>

                    </div>
                </div>
            </nav>

        </>
    )
}

export default Navbar
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  phoneNumber: '',
  memberName: '',
  emailAddress: '',
  clubName: '',
  hotel: {},
  orderId: 0,
  totalPrice: 0
};

const hotelSlice = createSlice({
  name: "hotel",
  initialState,
  reducers: {
    addOrderId : (state,action) => {
      return {...state, orderId: action.payload}
    },
    addHotel: (state,action) => {
      return { ...state, hotel: action.payload}
    },
    deleteHotel: (state,action) => {
      return { ...state, hotel: {}}
    },
    addRoom: (state, action) => {
        const filterRoomIndex = state.hotel.hotel_inventory.findIndex(data=>data.name_of_rooms===action.payload.name_of_rooms && data.hotel_id===action.payload.hotel_id)

        // console.log({filterRoom});
        state.hotel.hotel_inventory[filterRoomIndex] = action.payload
        return state;
    },
    addPhone: (state,action) => {
      return { ...state, phoneNumber: action.payload}
    },
    addMember: (state,action) => {
      return { ...state, memberName: action.payload}
    },
    addEmail: (state,action) => {
      return { ...state, emailAddress: action.payload}
    },
    addClub: (state,action) => {
      return { ...state, clubName: action.payload}
    },
    addTotalPrice: (state,action) => {
      state.totalPrice = 0
      state.hotel.hotel_inventory.forEach(data=>{
        state.totalPrice += ((data.price_per_room *  data.selectedRooms) || 0)
      })
      return state
    },
    removeTotalPrice: (state,action) => {
      state.totalPrice = 0
      return state
    }
  },

});

export const { addOrderId,addHotel,deleteHotel,addRoom,addClub,addEmail,addMember,addPhone,addTotalPrice,removeTotalPrice } = hotelSlice.actions;
export default hotelSlice.reducer;

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeading } from "components/misc/Headings";
import SocialIcon from "components/SocialIcon";
import './CheckoutPage.css'
// import { HotelContext } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { addClub, addEmail, addMember, addOrderId, addPhone } from "store/features/hotelSlice";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { API, imageUrl } from "generalfunction";
import { ToastContainer, toast } from 'react-toastify';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Checkout Page" }) => {

    const [ clubs, setClubs ] = useState([])

    const hotel = useSelector(state=>state.hotel)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const continueBookingHandler = () => {
      if (hotel.totalPrice <= 0 ){
        toast.error('Please select atleast one room')
        navigate('/booking')
      }else if(!hotel.phoneNumber || !hotel.memberName || !hotel.emailAddress || !hotel.clubName){
        toast.error('Please fill the details.')
      } else if (hotel.phoneNumber.length > 10 || hotel.phoneNumber.length < 10){
        toast.error('Phone number must be 10 digit!')
      } else if (hotel.memberName < 3) {
        toast.error('Name must be greater than 3 letters!')
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(hotel.emailAddress)){
        toast.error('Invalid email address')
      } else {
        const {
          phoneNumber,
          memberName,
          totalPrice,
          clubName,
          emailAddress,
          hotel: { id: hotelId, hotel_inventory: hotelInventory }
        } = hotel;
        
        const hotelObj = {
          phoneNumber,
          memberName,
          totalPrice,
          clubName,
          emailAddress,
          hotel: {
            hotelId,
            hotel_inventory: hotelInventory?.filter(({name_of_rooms,selectedRooms}) => { return (selectedRooms !== undefined && name_of_rooms !== 'Extra Room' )}).map(({ id, name_of_rooms: nameOfRooms, selectedRooms }) => ({
              id,
              name_of_rooms: nameOfRooms,
              selectedRooms
            }))
          }
        };
        
        console.log({ hotelObj });

        paymentAPI(hotelObj)
      }
    }

    const paymentAPI = async (body) => {
      try {
        const response = await API.post('/create-hotel-booking', body)
        console.log('create-hotel-booking',response.data);
        if(response.data.data){
          toast.success('Order is generated')
          dispatch(addOrderId(response.data.data))
          navigate('/order-summery')
        }
      } catch (error) {
        console.log(`payment api error: ${error}`);
      }
    }

    const fetchClubs = async () => {
      try{
        const response = await API.get("/get-club-list")
        const jsonData = response.data.data
        setClubs(jsonData)
      }catch(error){
        console.log({error});
        console.log(`club fetch error: ${error}`);
      }
    }
  
    useEffect(()=>{
      fetchClubs()
    },[])

  return (
    <AnimationRevealPage>
      <SocialIcon />
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <div className="row checkout-page-box">
                <form className="p-5 col-lg-5" action="https://www.rid3240.org/checkout/checkout.php" method="post">
                    <h2>Contact Information</h2>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                        <input type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={hotel.phoneNumber} onChange={(e)=>dispatch(addPhone(e.target.value))} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Member Name</label>
                        <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={hotel.memberName} onChange={(e)=>dispatch(addMember(e.target.value))} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={hotel.emailAddress} onChange={(e)=>dispatch(addEmail(e.target.value))}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Club Name</label>
                        <select className="form-select" aria-label="Default select example" value={hotel.clubName} onChange={(e)=>dispatch(addClub(e.target.value))}>
                        <option>Select your club</option>
                            {
                              clubs && clubs.map((club,i)=>{
                                return <option key={i} value={club.id}>{club.name}</option>
                              })
                            }
                        </select>
                    </div>
                    <div className="btn-footer">
                      <button type="button" className="btn btn-primary" onClick={continueBookingHandler}>Generate Order</button>
                    </div>
                </form>

                <form className="p-5 col-lg-6">
                    <h2>Details</h2>
                    <div className="col-lg-12 body">
                        <div className="details">
                          { hotel.hotel.hotel_images && <img src={`${imageUrl}${hotel?.hotel?.hotel_images[0].image_url}`} alt={`${hotel?.hotel?.hotel_name}`} />}
                        </div>  
                        <div className="details">
                            <p>Hotel Name</p>
                            <p>{hotel?.hotel?.hotel_name}</p>
                        </div>
                        <div className="details">
                            <p>Address</p>
                            <p>{hotel?.hotel?.complete_address}</p>
                        </div>
                        <div className="details">
                            <p><b>Room Type</b></p>
                            <p><b>Room occupied</b></p>
                        </div>
                        {
                          hotel?.hotel?.hotel_inventory?.map((data,i)=>{
                            if(!data.selectedRooms){
                              return
                            }else {

                              return data.price_per_room > 0 && <div className="details" key={i}>
                                        <p>{data.name_of_rooms}</p>
                                        <p>{ data.selectedRooms>0 ? `${data.selectedRooms} x ₹${data.price_per_room}/-` : 0}</p>
                                      </div>
                            }
                          })
                        }
                        <hr/>
                        
                        <div className="details">
                            <p>Total Price </p>
                            <p>&#8377;{hotel?.totalPrice}/-</p>
                        </div>
                    </div>
                </form>
              </div>
          </Text>
        </ContentWithPaddingXl>
        <ToastContainer />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

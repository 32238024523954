import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeading } from "components/misc/Headings";
import SocialIcon from "components/SocialIcon";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <SocialIcon />
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Effective Date: 11-Aug-2023</p><br/>

              <strong>Introduction:</strong><br/>
              <p>Welcome to the Rotary District 3240 website. This Privacy Policy outlines how we collect, use, and protect your personal information. By accessing or using our website, you agree to the terms outlined in this policy.</p><br/>

              <strong>Information We Collect:</strong><br/>
              <p>We may collect personal information such as your name, email address, phone number, and Rotary club affiliation when you register, subscribe, or interact with our website. Additionally, we may collect non-personal information such as browser type, device information, and usage data.</p><br/>

              <strong>Use of Information:</strong><br/>
              <p>We use your personal information to provide you with relevant information, updates, and services related to Rotary District 3240. This may include sending newsletters, event notifications, and important announcements. We may also use aggregated, non-personal information for analytical purposes to improve our website and services.</p><br/>

              <strong>Sharing of Information:</strong><br/>
              <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with authorized Rotary District 3240 personnel and service providers who assist us in operating our website and delivering services. We may also disclose information if required by law or to protect our rights, safety, or the rights and safety of others.</p><br/>

              <strong>Cookies and Tracking Technologies:</strong><br/>
              <p>Our website uses cookies and similar tracking technologies to enhance your browsing experience. You can control cookie preferences through your browser settings. Please note that disabling cookies may impact certain features of our website.</p><br/>

              <strong>Security:</strong><br/>
              <p>We take reasonable measures to safeguard your personal information from unauthorized access, disclosure, or alteration. However, no data transmission over the internet is completely secure. We cannot guarantee the security of your information.</p><br/>

              <strong>Children's Privacy:</strong><br/>
              <p>Our website is not intended for children under the age of 13. We do not knowingly collect or store personal information from children under 13 without parental consent.</p><br/>

              <strong>Updates to Privacy Policy:</strong><br/>
              <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the revised policy will be effective immediately upon posting.</p><br/>

              <strong>Contact Us:</strong><br/>
              <p>If you have any questions or concerns about our Privacy Policy, please contact us at ramesh3240des2324@gmail.com </p><br/>

              <p>By using our website, you consent to the terms of this Privacy Policy.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

import axios from "axios";

// Local path
// export const baseURL = "http://10.5.49.211/projects/Rotary3240/api/v1/app";
// export const imageUrl = "http://10.5.49.211/projects/Rotary3240/public/upload/"

// Server path
// export const baseURL = "https://rid3240.avantikain.com/api/v1/app";
export const baseURL = "https://api.rid3240.org/api/v1/app";
export const imageUrl = "https://rid3240.avantikain.com/public/upload/"

export const API = axios.create({
  baseURL: `${baseURL}`,
  headers: {
    'Access-Control-Allow-Origin': '*', // Replace with your frontend URL
    // Other headers as needed
    'withCredentials': true
  },
});


export const axiosInstance = axios.create({
  baseURL: `${baseURL}`,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  function (error) {
    console.log("error", error)
    return Promise.reject(error);
  }
);

import React,{useEffect,useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import { Heading } from "components/misc/Headings";
import Header, { NavLinks } from "../../components/headers/light";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Navbar from '../../components/Navbar';
import SocialIcon from "components/SocialIcon";



export const DistrictCommittee = ({roundedHeaderButton = true}) => {

    // const [refreshed, setRefreshed] = useState(false);

    // useEffect(() => {
    //     if (!refreshed) {
    //       window.location.reload();
    //       setRefreshed(true);
    //     }
    //   }, [refreshed]);

    // useEffect(() => {
    //     window.location.reload();
    // },[])
    return (
        <AnimationRevealPage>
            <SocialIcon />
            <Header roundedHeaderButton={roundedHeaderButton} />
            <Navbar />
            <ContainerBase className="m-5 text-center">

                <div className="my-3">

                    <Heading>District Committee 2023-24</Heading>
                    <div className="mt-3">
                        <table width="1200" className="mx-auto">
                            <thead>
                                <tr>
                                    <td width="100">S No.</td>
                                    <td width="395">Committees Name</td>
                                    <td width="264">Position Name</td>
                                    <td width="274">Members Name</td>
                                    <td width="274">Club</td>
                                </tr>
                            </thead>
                            <tbody className="">

                                <tr><td>
                                </td><td>
                                    </td><td>
                                    </td><td>
                                    </td>
                                    <td width="143"> </td>
                                </tr><tr>
                                    <td>1</td>
                                    <td>District Membership Committee</td>
                                    <td>Membership Chair</td>
                                    <td>Rtn. Tuhin Chowdhary</td>
                                    <td width="143">Tagore land</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td width="143"> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td>Membership Development</td>
                                    <td>Co-Chair</td>
                                    <td>Rtn. Mahmood Hossain</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Co-Chair</td>
                                    <td>Rtn. Kiron Joshi</td>
                                    <td width="143">Shillong</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member</td>
                                    <td>Rtn. Harshed Seth</td>
                                    <td width="143">Burdwan Greater</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member</td>
                                    <td>Rtn. Ujjoyani Biswas</td>
                                    <td width="143">Raiganj</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td width="143"> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td>Membership Engagement &amp; Retaintion</td>
                                    <td>Co-Chair</td>
                                    <td>Rtn. Arindam Dutta</td>
                                    <td width="143">Bolpur Santiniketan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Co-Chair</td>
                                    <td>Rtn. Madhuchandra Bhattacharjee</td>
                                    <td width="143">Guwahati Smart city</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member</td>
                                    <td>Rtn. Pradip Sen</td>
                                    <td width="143">Gour Banga</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member</td>
                                    <td>Rtn. Kamal Agarwal</td>
                                    <td width="143">Siliguri Green</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td width="143"> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td>Rotary Extension &amp; New Club Development</td>
                                    <td>Co-Chair</td>
                                    <td>Rtn. Gangadhar Khandelwal</td>
                                    <td width="143">Burdwan South</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Co-Chair</td>
                                    <td>Rtn. Ritesh Misruf</td>
                                    <td width="143">RC Tezpur</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member</td>
                                    <td>Rtn. Samaresh Choudhury</td>
                                    <td width="143">Durgapur Smart City</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member</td>
                                    <td>Rtn. Shankar Choudhury</td>
                                    <td width="143">Hailakandi</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td width="143"> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td>Orientation &amp; Education</td>
                                    <td>Co-Chair</td>
                                    <td>Rtn. Krishna Das Gupta</td>
                                    <td width="143">Orchid City Shillong</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Co-Chair</td>
                                    <td>Rtn. Manju Chandak</td>
                                    <td width="143">Durgapur Central</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member</td>
                                    <td>Rtn. Shambhu Lal Agarwala</td>
                                    <td width="143">Balurghat</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member</td>
                                    <td>Sillong</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Co-Chair</td>
                                    <td>PDG Rintu Guha Niyogi</td>
                                    <td width="143">RC Durgapur Smart City</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Co-Chair</td>
                                    <td>PP Dipak Rudra</td>
                                    <td width="143">RC Asansol Greater</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Co-Chair</td>
                                    <td>PP Taimur Raja Choudhury</td>
                                    <td width="143">RC Silcher</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Secretary</td>
                                    <td>PP Janmenjay Mondal</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Treasurer</td>
                                    <td>PP Arvind Khandelwal</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td width="143"> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member (Registration)</td>
                                    <td>Rtn. Puja Banerjee</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rtn. Chuya Panja</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rtn. Amit Hazra Choudhury</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rotractor Arundhati Coudhury</td>
                                    <td width="143">Rotractor Club of Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td width="143"> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member (Transport)</td>
                                    <td>PP Rakesh Surana</td>
                                    <td width="143">RC Burdwan South</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rtn. Arijit sen</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rotractor Aslam Parbhej Aslam</td>
                                    <td width="143">Burdwan Rotract Club</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td width="143"> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member (Accomodation)</td>
                                    <td>PP Chandrajit Kundu</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rtn Samantak Haldar</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rtn. Sanjit Hati</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rtn. Debasis Mazumdar</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td width="143"> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member (Venue Control)</td>
                                    <td>PP Harinarayan Khandelwal</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>PP Kalyan Kumar Mukherjee</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>PP Abhijit Choudhury</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rotractor Sourav Gupta</td>
                                    <td width="143">Rotractor Club of Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td width="143"> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member (Food &amp; Bev.)</td>
                                    <td>Rtn. Gupteswar Bhakat</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rtn. Sanket Roy</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td width="143"> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member (Promotion)</td>
                                    <td>PP Abhishek Jitani</td>
                                    <td width="143">Guwahati City</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>PP Madhumita Paul</td>
                                    <td width="143">Greenland Silchar</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td width="143"> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member (Culture)</td>
                                    <td>Rtn. Dr. Subhendu Dwivedi</td>
                                    <td width="143">Burdwan South</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rtn. Suman Kangsha Banik</td>
                                    <td width="143">Agartala City</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rtn. Rajesh Bhatra</td>
                                    <td width="143">Guwahati South</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rtn. Mukti Prakash Sarkar</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td width="143"> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Member (E-Communication)</td>
                                    <td>Rtn. Soumen Mondal</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rtn Rajesh Surana</td>
                                    <td width="143">RC Burdwan</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ContainerBase>
            <Footer />
        </AnimationRevealPage>
    )
}



import tw from "twin.macro";

export const PrimaryLink = tw.a`cursor-pointer font-bold text-primary-500 border-b-2 border-transparent hocus:border-primary-500 hocus:text-primary-800 transition duration-300`;
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;
export const LoginLink = tw(NavLink)`
  lg:mx-5
  px-8 py-3 rounded bg-green-700 text-gray-100
  hocus:bg-green-800 hocus:text-gray-200 focus:shadow-outline
  border-b-0
  text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 lg:mx-5
  px-8 py-3  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-full text-sm text-center mr-2
`;
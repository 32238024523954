import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import { Heading } from "components/misc/Headings";
import Header, { NavLinks } from "../../components/headers/light";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Navbar from '../../components/Navbar';
import SocialIcon from "components/SocialIcon";

export const Rotary_Year = ({
    roundedHeaderButton = true
}
) => {


    return (
        <AnimationRevealPage>
            <SocialIcon />
            <Header roundedHeaderButton={roundedHeaderButton} />
            <Navbar />
            <ContainerBase className="m-5 text-center">
                <Heading>Brand Center</Heading>
                <div className="d-flex flex-wrap mt-5 w-75 mx-auto border rounded shadow">
                    <img src="../../../../images/logo1.png" className="w-25 px-3 py-3  " alt="" />
                    <img src="../../../../images/logo2.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo3.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo4.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo5.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo6.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo7.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo8.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo9.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo10.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo11.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo12.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo13.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo14.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo15.png" className="w-25 px-3 py-3  " />
                    <img src="../../../../images/logo16.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo17.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo18.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo19.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo20.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo21.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo22.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo23.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo24.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo25.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo26.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo27.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo28.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo29.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo30.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo31.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo32.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo33.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo34.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo35.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo36.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo37.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo38.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo39.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo40.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo41.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo42.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo43.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo44.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo45.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo46.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo47.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo48.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo49.png" className="w-25 px-3 py-3 " />
                    <img src="../../../../images/logo50.png" className="w-25 px-3 py-3 " />
                </div>
            </ContainerBase>
            <Footer />
        </AnimationRevealPage>
    )
}



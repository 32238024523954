import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeading } from "components/misc/Headings";
import SocialIcon from "components/SocialIcon";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Disclaimer Policy" }) => {
  return (
    <AnimationRevealPage>
      <SocialIcon />
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <strong>Accuracy of Information:</strong><br/>
            <p>The information provided on the Rotary District 3240 website is intended for general informational purposes only. While we strive to ensure the accuracy and timeliness of the content, we cannot guarantee the completeness, reliability, or suitability of the information provided. Users are advised to independently verify any information before relying on it.</p><br/>

            <strong>No Legal or Professional Advice:</strong><br/>
            <p>The content on this website is not intended to constitute legal, financial, professional, or any other type of advice. Users should consult appropriate professionals or experts for specific advice related to their individual situations.</p><br/>

            <strong>Third-Party Content and Links:</strong><br/>
            <p>Our website may include links to third-party websites, documents, or resources for convenience. Rotary District 3240 is not responsible for the accuracy, reliability, or legality of such external content. The inclusion of any links does not necessarily imply endorsement or association with the content.</p><br/>

            <strong>Availability and Technical Issues:</strong><br/>
            <p>While we make efforts to keep the website accessible and operational, Rotary District 3240 does not guarantee uninterrupted or error-free access to the website. We are not liable for any technical issues, downtime, or disruptions that may affect your use of the website.</p><br/>

            <strong>Changes to Content and Policy:</strong><br/>
            <p>Rotary District 3240 reserves the right to modify, update, or remove any content on the website without prior notice. Similarly, this disclaimer policy may be revised at any time. Users are advised to review the policy periodically for any changes.</p><br/>

            <strong>Limitation of Liability:</strong><br/>
            <p>Rotary District 3240, its officers, volunteers, and affiliates shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use or inability to use the website, its content, or any linked resources.</p><br/>

            <strong>Acceptance of Terms:</strong><br/>
            <p>By using the Rotary District 3240 website, users acknowledge and agree to abide by the terms of this disclaimer policy.</p><br/>

            <p>For any further questions or clarifications regarding this disclaimer policy, please contact us at ramesh3240des2324@gmail.com .</p><br/>

            <p>This disclaimer policy is effective from 11-08-2023 .</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

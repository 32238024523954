import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeading } from "components/misc/Headings";
import SocialIcon from "components/SocialIcon";
import './CheckoutPage.css'
// import { HotelContext } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import {  addEmail, addMember, addPhone } from "store/features/hotelSlice";
import {  imageUrl } from "generalfunction";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Order Summery" }) => {

    const hotel = useSelector(state=>state.hotel)
    const dispatch = useDispatch()

  return (
    <AnimationRevealPage>
      <SocialIcon />
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <div className="row checkout-page-box">
                <form className="p-5 col-lg-5" action="https://www.rid3240.org/checkout/checkout.php" method="post">
                    <h2>Contact Information</h2>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                        <input type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={hotel.phoneNumber} onChange={(e)=>dispatch(addPhone(e.target.value))} disabled/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Member Name</label>
                        <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={hotel.memberName} onChange={(e)=>dispatch(addMember(e.target.value))} disabled/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={hotel.emailAddress} onChange={(e)=>dispatch(addEmail(e.target.value))} disabled/>
                    </div>
                    {/* Hidden datas */}
                    <input type="hidden" name="amount" value={hotel.totalPrice} />
                    <input type="hidden" name="orderId" value={`rid3240_ord_0_${hotel.orderId}`} />
                    <input type="hidden" name="order-summery" value={ JSON.stringify(hotel)} />
                    <div className="btn-footer">
                        <button type="submit" className="btn btn-primary" >Procced to Payment</button> 
                    </div>
                </form>

                <form className="p-5 col-lg-6">
                    <h2>Details</h2>
                    <div className="col-lg-12 body">
                        <div className="details">
                          { hotel.hotel.hotel_images && <img src={`${imageUrl}${hotel?.hotel?.hotel_images[0].image_url}`} alt={`${hotel?.hotel?.hotel_name}`} />}
                        </div>  
                        <div className="details">
                            <p>Hotel Name</p>
                            <p>{hotel?.hotel?.hotel_name}</p>
                        </div>
                        <div className="details">
                            <p>Address</p>
                            <p>{hotel?.hotel?.complete_address}</p>
                        </div>
                        <div className="details">
                            <p><b>Room Type</b></p>
                            <p><b>Room occupied</b></p>
                          </div>
                        {
                          hotel?.hotel?.hotel_inventory?.map((data,i)=>{
                            if(!data.selectedRooms){
                              return
                            }else {

                              return data.price_per_room > 0 && <div className="details" key={i}>
                                        <p>{data.name_of_rooms}</p>
                                        <p>{ data.selectedRooms>0 ? `${data.selectedRooms} x ₹${data.price_per_room}/-` : 0}</p>
                                      </div>
                            }
                          })
                        }
                        <hr/>
                        
                        <div className="details">
                            <p>Total Price </p>
                            <p>&#8377;{hotel?.totalPrice}/-</p>
                        </div>
                    </div>
                </form>
              </div>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line

import ComponentRenderer from "ComponentRenderer.js";
import MainLandingPage from "MainLandingPage.js";
import ThankYouPage from "ThankYouPage.js";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutUs from "pages/AboutUs";

import ReportingCMR from "pages/ReportingCMR";
import Projectupload from "pages/Projectupload";
import { Login } from "pages/Login";
import { DistrictCommittee } from "pages/District/DistrictCommittee";
import { District_Secretariat } from "pages/District/District_Secretariat";
import { Assistant_Governor } from "pages/District/Assistant_Governor";
import { Projects } from "pages/Projects";
import { Rotary_Year } from "pages/RotaryYear";
import { Contact_Us } from "pages/Contact_Us";
import Enquiry_Form from "components/Enquiry_Form";
import SocialIcon from "components/SocialIcon";
import Districtleadercarousel from "components/district/districtleadercarousel";
import { District_Events } from "pages/District/District_Events";
import PrivacyPolicy from "pages/PrivacyPolicy";
import RefundPolicy from "pages/RefundPolicy";
import TermsConditionsPolicy from "pages/TermsConditionsPolicy";
import DisclaimerPolicy from "pages/DisclaimerPolicy";
import CancellationPolicy from "pages/CancellationPolicy";
import Booking from "pages/Booking/Booking";
import PaymentSuccess from "pages/Booking/PaymentSuccess";
import CheckoutPage from "pages/Booking/CheckoutPage";
import { Provider } from 'react-redux'
import store from './store/store.js'
import 'react-toastify/dist/ReactToastify.css';
import OrderSummery from "pages/Booking/OrderSummery.js";

export default function App() {
  return (
    <>
      <Provider store={store}>
      <GlobalStyles />
      <Router>
        <Routes>
          {/* <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
          <Route path="/components/:type/:name" element={<ComponentRenderer />} /> */}
          {/* <Route path="/thank-you" element={<ThankYouPage />} /> */}
          <Route path="/" element={<MainLandingPage />} />
          <Route path="/dashboard" element={<AboutUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/ReportingCMR" element={<ReportingCMR/>} />
          <Route path="/Projectupload" element={<Projectupload/>}/>
          <Route path="/district-committee" element={<DistrictCommittee/>}/>
          <Route path="/district_secretariate" element={<District_Secretariat/>}/>
          <Route path="/assistant_governor" element={<Assistant_Governor/>}/>
          <Route path="/clubs_in_action" element={<Projects/>}/>
          <Route path="/brand_center" element={<Rotary_Year/>}/>
          <Route path="/contact_us" element={<Contact_Us/>}/>
          <Route path="/enquiry_form" element={<Enquiry_Form/>}/>
          <Route path="/social_icon" element={<SocialIcon/>}/>
          <Route path="/RI_President" element={<Districtleadercarousel/>}/>
          <Route path="/district_events" element={<District_Events/>}/>
          <Route path="/cancellation_policy" element={<CancellationPolicy/>}/>
          <Route path="/privacy_policy" element={<PrivacyPolicy/>} />
          <Route path="/refund_policy" element={<RefundPolicy/>} />
          <Route path="/terms_&_conditions_policy" element={<TermsConditionsPolicy/>} />
          <Route path="/disclaimer_policy" element={<DisclaimerPolicy/>} />
          <Route path="/booking" element={<Booking/>} />
          <Route path="/payment_success" element={<PaymentSuccess/>} />
          <Route path="/checkout_page" element={<CheckoutPage/>} />
          <Route path="/order-summery" element={<OrderSummery/>} />
          <Route path="*" element={<MainLandingPage />} />
        </Routes>
      </Router>
      </Provider>
      {/* <Router>
        <Switch>
          <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
          <Route path="/components/:type/:name" element={<ComponentRenderer />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/" element={<MainLandingPage />} />
          <Route path="dashboard" element={<AboutUs />} />
          <Route path="login" element={<Login />} />
          <Route path="ReportingCMR" element={<ReportingCMR />} />
          <Route path="Projectupload" element={<Projectupload />} />
          <Route path="district-committee" element={<DistrictCommittee />} />
          <Route path="district_secretariate" element={<District_Secretariat />} />
          <Route path="assistant_governor" element={<Assistant_Governor />} />
          <Route path="clubs_in_action" element={<Projects />} />
          <Route path="brand_center" element={<Rotary_Year />} />
          <Route path="contact_us" element={<Contact_Us />} />
          <Route path="enquiry_form" element={<Enquiry_Form />} />
          <Route path="social_icon" element={<SocialIcon />} />
          <Route path="RI_President" element={<Districtleadercarousel />} />
          <Route path="district_events" element={<District_Events />} />
        </Switch>
      </Router> */}
    </>
  );
}


import React from "react";
import { Container } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
// import { SectionHeading } from "components/misc/Headings";
import { Link } from "react-router-dom";
import './Hotel.css'
import { useDispatch, useSelector } from "react-redux";
import { addHotel, addRoom, addTotalPrice } from "store/features/hotelSlice";
import { imageUrl } from "generalfunction";
import { ToastContainer, toast } from 'react-toastify';

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Hotel",hotel,hotelId,totalHotels }) => {
  const { hotel_name,complete_address,hotel_images,common_service_included,notes,gst_charge,price_per_extra_bed } = hotel
  const hotelInfo = useSelector(state=>state.hotel)
  const dispatch = useDispatch()

  const onSelectRoomHandler = (e,room) => {
    console.log(e.target.value, room);
    if (e.target.value < 0 || e.target.value.includes('.')) {
      toast.error('input number must greater than 0 or integer')
    } else if(!room.available_inventory){
      // dispatch(addRoom({ ...room, selectedRooms:Number(e.target.value)}))
      // dispatch(addTotalPrice())
      toast.error('This room is not available for booking!')
    } else if(Number(room.available_inventory) >= Number(e.target.value)){
      dispatch(addRoom({ ...room, selectedRooms:Number(e.target.value)}))
      dispatch(addTotalPrice())
    } else {
      toast.error('Your value must be under available rooms.')
      dispatch(addRoom({ ...room, selectedRooms:0}))
      dispatch(addTotalPrice())
    }
  }

  const onVisibilityHandler = () => {
    for(let i=0; i<totalHotels; i++){
      if(i === hotelId){
        document.querySelector(`.div_hide_${i}`).style.display = "block"
        dispatch(addHotel(hotel))
        dispatch(addTotalPrice())
        continue;
      }
      document.querySelector(`.div_hide_${i}`).style.display = "none"
    }
  }

  return (<>
      <Container>
          <Text>
            <div className="row">
              <div className="col-lg-12 shadow-2xl card">
                <div className="col-lg-4">
                  <img src={`${imageUrl}${hotel_images[0]?.image_url}`} alt={`${hotel_name}`}/>
                </div>
                <div className="p-2 col-lg-6">
                  <h3>{hotel_name}</h3>
                  <h4>{complete_address}</h4>
                  { notes && <><strong><small><i>{notes}</i></small></strong><br/></>}
                  
                  <small><strong>{common_service_included}</strong></small>
                  <ul className="room-names">
                    {
                      hotel && hotel.hotel_inventory.map((room,i)=>{
                          return room.price_per_room > 0 &&  <li key={i}>{`${room.total_qty} Rooms`} [{room.name_of_rooms}] {`@ ₹${room.price_per_room}.00 for 2 Nights `}</li>
                      })
                    }
                    {
                      price_per_extra_bed !== 0 
                      ? <li>Extra Bed @ Rs.{`${ price_per_extra_bed+(price_per_extra_bed*(gst_charge/100)) }/-`}</li>
                      : <li>No Extra bed available</li>
                    }
                  </ul>
                </div>
                <div className="div-btn col-lg-2">
                  <button className="btn btn-primary" onClick={onVisibilityHandler}>See Availability</button>
                </div>
              </div>
            </div>

            { 
              <Text className={`div_hide_${hotelId}`} style={{display:'none'}}>
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Room Type</th>
                      <th scope="col">Rooms</th>
                      <th scope="col">Price/-</th>
                      <th scope="col">Select Rooms</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      hotel && hotel.hotel_inventory.map((room,i)=>{
                              return room.price_per_room > 0 && <tr key={i}>
                              <th scope="row">{room.name_of_rooms}</th>
                              <td>{room.available_inventory}</td>
                              <td>&#8377;{room.price_per_room}/-</td>
                              <td><input type="number" className="select-room" value={room.selectedRooms} onChange={e=>onSelectRoomHandler(e,room)} title="Number of rooms"/></td>
                            </tr>
                      })
                    }
                      <tr>
                        <td colSpan={'5'} style={{textAlign:'right'}}>Total: &#8377;{hotelInfo.totalPrice}/-&nbsp;
                          {
                            (hotelInfo.totalPrice>0)
                            ?  <Link className="btn btn-primary" to={'/checkout_page'}>Book Now</Link> 
                            :  <button className="btn btn-primary" disabled>Book Now</button> 
                          }
                        </td>
                      </tr>
                  </tbody>
                </table>
              </Text>
            }
          </Text>
          <ToastContainer />
      </Container>
 </>)
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { Heading } from "components/misc/Headings";
import Header, { NavLinks } from "../../components/headers/light";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Hero from '../../components/hero/TwoColumnWithInput';
import Navbar from '../../components/Navbar';
import SocialIcon from "components/SocialIcon";



export const Assistant_Governor = ({


    roundedHeaderButton = true

}
) => {
    const assistant = [{
        id: 1,
        zone: 'I',
        name: 'Avishek Dokania',
        club: 'Asansol',
        phoneno: '9434008421',
        emailid: 'avishekdokania@gmail.com'
    }, {
        id: 2,
        zone: 'II',
        name: 'Anuran Lall Singh Handa',
        club: 'Ukhra',
        phoneno: '9932444154',
        emailid: 'anuranlshanda@gmail.com'
    }, {
        id: 3,
        zone: 'III',
        name: 'Santosh Maheshwari',
        club: 'Durgapur Central',
        phoneno: '9832176615',
        emailid: 'santosh.dgp13@yahoo.com'
    },
    {
        id: 4,
        zone: 'IV',
        name: 'Shovan Kumar',
        club: 'Burdwan South',
        phoneno: ['9434177776', '9233333390'],
        emailid: 'shovan.law@gmail.com',
    },
    {
        id: 5,
        zone: 'V',
        name: 'Dr. Shubhankar Ghosh',
        club: 'Tagoreland',
        phoneno: ['9474548535', '7908472575'],
        emailid: 'rumashubhankar@gmail.com',
    },
    {
        id: 6,
        zone: 'VI',
        name: 'Pradip Kr. Sen',
        club: 'Silk City Malda',
        phoneno: '9434323419',
        emailid: 'pksen30@gmail.com',
    },
    {
        id: 7,
        zone: 'VII',
        name: 'Sanchita Bhattacharrya',
        club: 'Raiganj',
        phoneno: '9475105234',
        emailid: 'sanchitapartha@gmail.com',
    },
    {
        id: 8,
        zone: 'VIII',
        name: 'Shiv Shankar Sarkar',
        club: 'Siliguri Metropolitan',
        phoneno: '8697739893',
        emailid: 'Shivshankarsarkar@yahoo.com',
    },
    {
        id: 9,
        zone: 'IX',
        name: 'Gopal Krishna Kayan',
        club: 'Siliguri Uttorayon',
        phoneno: '9932025280',
        emailid: 'gopalkayan@gmail.com',
    },
    {
        id: 10,
        zone: 'X',
        name: 'Jorden Norbhu',
        club: 'Darjeeling',
        phoneno: '9434044218',
        emailid: 'jorden.norbhu@gmail.com',
    },
    {
        id: 11,
        zone: 'XI',
        name: 'Vikas Golyan (Vicky)',
        club: 'Kalimpong',
        phoneno: ['9800193000', '9832093000'],
        emailid: ['golyanvikash@gmail.com', 'vikashgolyan@hotmail.com'],
    },
    {
        id: 12,
        zone: 'XII',
        name: 'Anurag Jain',
        club: 'Gauhati West',
        phoneno: ['9435016657', '9957507328'],
        emailid: 'anuragrotary@gmail.com',
    },
    {
        id: 13,
        zone: 'XIII',
        name: 'Satish Kasera',
        club: 'Guwahati Metro',
        phoneno: '9435019357',
        emailid: 'kaserask@rediffmail.com',
    },
    {
        id: 14,
        zone: 'XIV',
        name: 'Partha Dasgupta',
        club: 'Bongaigaon',
        phoneno: ['8133007222', '9435020678'],
        emailid: 'capitalpartha1@gmail.com',
    },
    {
        id: 15,
        zone: 'XV',
        name: 'Arun Kumar Agarwal',
        club: 'Nagaon',
        phoneno: '9435061476',
        emailid: 'nggarun71@gmail.com',
    },
    {
        id: 16,
        zone: 'XVI',
        name: 'Indira Gogoi',
        club: 'Golaghat',
        phoneno: ['6003149751', '9435515241'],
        emailid: 'indiragogoi.glt@gmail.com',
    },
    {
        id: 17,
        zone: 'XVII',
        name: 'Dr. Nitin Agarwal',
        club: 'Dimapur',
        phoneno: '9402830558',
        emailid: 'docnitinagarwal@gmail.com',
    },
    {
        id: 18,
        zone: 'XVIII',
        name: 'Amit Sharma',
        club: 'Shillong',
        phoneno: '9436103734',
        emailid: 'amitdist3240@gmail.com',
    },
    {
        id: 19,
        zone: 'XIX',
        name: 'Samir Todi',
        club: 'Dibrugarh',
        phoneno: '9435032301',
        emailid: 'samirtodi@gmail.com',
    },
    {
        id: 20,
        zone: 'XX',
        name: 'Arup Kr. Goswami',
        club: 'Digboi',
        phoneno: ['9435337266', '8134030639'],
        emailid: 'arupgoswami15@gmail.com',
    },
    {
        id: 21,
        zone: 'XXI',
        name: 'Dr. Siddhaartha Sankar Bhattacharjee',
        club: 'Silchar',
        phoneno: '9435071196',
        emailid: 'sidharth_dr@yahoo.com',
    },
    {
        id: 22,
        zone: 'XXII',
        name: 'Rafique Ahmed Choudhury',
        club: 'Karimganj',
        phoneno: ['9854058025', '9435075208'],
        emailid: 'rachoudhury1963@gmail.com',
    },
    {
        id: 23,
        zone: 'XXIII',
        name: 'Nirmal Kr. Singhi',
        club: 'Agartala City',
        phoneno: ['9831041277', '9862567021'],
        emailid: 'singhinirmal@yahoo.co.in',
    },
    ]

    return (
        <AnimationRevealPage>
            <SocialIcon />
            <Header roundedHeaderButton={roundedHeaderButton} />
            <Navbar />
            <ContainerBase className="m-5 text-center">
                <div className="mt-3 mb-5">
                    <Heading>Assistant Governor's 2023-24</Heading>

                    <div className="mt-3">
                        <table width="auto" className="mx-auto text-center">
                            <thead>
                                <tr>
                                    <td>Zone</td>
                                    <td >Full Name</td>
                                    <td>Club Name</td>
                                    <td>Mobile No.</td>
                                    <td>E-mail Id</td>
                                </tr>
                            </thead>
                            <tbody className="">
                                {assistant.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.zone}</td>
                                            <td>{item.name}</td>
                                            <td>{item.club}</td>
                                            <td>{item.phoneno}</td>
                                            <td>{item.emailid}</td>
                                        </tr>
                                    )
                                })}



                            </tbody>
                        </table>
                    </div>
                </div>
            </ContainerBase>
            <Footer />
        </AnimationRevealPage>
    )
}



import { useNavigate } from 'react-router-dom'
import './Offer.css'
import { useEffect, useState } from 'react'

const Offer = () => {
    const navigate = useNavigate()
    const [isOpen,setIsOpen] = useState(true)
    
    const bookingHotel = () => {
        navigate('/booking')
    }

    return <>
        {
            isOpen && <div className="offer-box">
            <div className=" row offer-body">
                    <img className='offer-img' src='/images/hotels/Pop_Up_image.jpg' alt='promotion' />
                    <div className='row'>
                        <div className='col-lg-6'>
                            <a className='btn btn-outline-primary btn-redirect' href='https://forms.gle/s6NcskST9QCB9jRa9' target='_blank' >Conference Registration</a>
                        </div>
                        <div className='col-lg-6'>
                            <button className='btn btn-primary btn-redirect' onClick={bookingHotel}>Hotels Booking</button>
                        </div>
                    </div>
                    <button className='close-btn' onClick={()=>setIsOpen(false)}><img src='https://cdn3.iconfinder.com/data/icons/pyconic-icons-1-2/512/close-circle-1024.png'/></button>
            </div>
        </div>
        }
        
    </>
}

export default Offer
import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeading } from "components/misc/Headings";
import SocialIcon from "components/SocialIcon";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Terms & Conditions Policy" }) => {
  return (
    <AnimationRevealPage>
      <SocialIcon />
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

          <p>Effective Date: 11-08-2023</p><br/>

            <ul>
                <li><strong>Acceptance of Terms</strong><br/>

                By accessing and using the Rotary 3240 website ("Website"), you agree to abide by these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using the Website.
                </li>
                <li><strong>Intellectual Property</strong><br/>

                All content, logos, images, graphics, and materials on the Website are the intellectual property of Rotary 3240 and are protected by copyright and other applicable laws. You may not reproduce, distribute, or use any content from the Website without prior written permission from Rotary 3240.
                </li>
                <li><strong>User Conduct</strong><br/>

                You agree not to engage in any activities that may disrupt or interfere with the proper functioning of the Website, including but not limited to hacking, uploading malicious code, or attempting unauthorized access.
                </li>
                <li><strong>Privacy</strong><br/>

                We are committed to protecting your privacy. Our Privacy Policy outlines how we collect, use, and store your personal information. By using the Website, you consent to the practices described in the Privacy Policy.
                </li>
                <li><strong>Links to Third-Party Websites</strong><br/>

                The Website may contain links to third-party websites. Rotary 3240 is not responsible for the content or practices of these external sites. Your interactions with third-party websites are subject to their respective terms and conditions.
                </li>
                <li><strong>Disclaimer of Warranty</strong><br/>

                The Website is provided on an "as is" and "as available" basis. Rotary 3240 makes no representations or warranties of any kind, express or implied, regarding the Website's accuracy, reliability, or availability.
                </li>
                <li><strong>Limitation of Liability</strong><br/>

                Rotary 3240 shall not be liable for any damages, losses, or expenses arising from the use of the Website or inability to access it. This includes direct, indirect, incidental, consequential, or punitive damages.
                </li>
                <li><strong>Changes to Terms and Conditions</strong><br/>

                Rotary 3240 reserves the right to modify these Terms and Conditions at any time. Any changes will be effective upon posting on the Website. Your continued use of the Website after such changes constitutes your acceptance of the modified terms.
                </li>
                <li><strong>Governing Law</strong><br/>

                These Terms and Conditions are governed by and construed in accordance with the laws of Tezpur Jurisdiction. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of Tezpur Jurisdiction.
                </li>            
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import { Heading, Sub_Heading, Subheading } from "components/misc/Headings";
import Header, { NavLinks } from "../../components/headers/light";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Navbar from '../../components/Navbar';
import SocialIcon from "components/SocialIcon";
import { baseURL, imageUrl } from "generalfunction";



export const District_Events = ({


    roundedHeaderButton = true

}
) => {

    const [data, setData] = useState()


    const isEmpty = (value) => {
        if (value === undefined || value === null || value?.length === 0) {
            return true
        }
        else {
            return false
        }
    }

    const onLoad = async () => {

        const event_type = 'District';
        const config = {
            headers: {
                'Authorization': 'Bearer 151|UmBb6trcncXKgfzuS4p1cutij4i00UgWm0IhuG1Y'
                // 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOTNhODNjODQ0MTFmZjQ4YWVmYWEzMDY2Y2E2MmRkMmUyMzQzNTk5YmEwMzBlNGEyYWM3NWMzZDQxNmRhOWUwNWQ2NjVjYzZhYmJmNTEwOTQiLCJpYXQiOjE2ODA1MDAwMjIuODQxMDAyLCJuYmYiOjE2ODA1MDAwMjIuODQxMDA3LCJleHAiOjE3MTIxMjI0MjIuNzI3MjAyLCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.Tr_6B-o4S9YU07ZQKDEl8ib5yFWJ2i-bSI6xQ8b5TkpFlxIL0U1IJ74E5OLtnCRE42YEVNrvMbXp7EU4LQDblEVhPTHBwp3wcIolGtFpYi1p_LhX6MB6uUivNOIdB-jFUo5sH816QUitidqPnBkPb8sUCioPSJm_lziBP7m23MR9X-1hjjIKi_N1k4hNTo717mdCqa6Atjjc1ITN1Pb7Jyptti4dS3KQH695OaFZMdpls0W70OEjpGKGFhbKV-CU4B7cyGJPKldJH85p62HpL5-8OVeQFXugpuoly06PUo2Je1POM785hPXy_HRgLuJufd0jG9_Vm2NPw8UvtzW6VY-wRtUu_0Gvt6Rrupw6p_nm_tipvUJY6I4IfElQMEkLbype1h_Ey6RDI3vxGwOfhK0tZZkVS190gChcQDYrG8wAmZBraOr1Dy1ZBaFDh9fnztAeSdqQMDW07bDcZKRTo9vHWI0l3yLmsX9ijGkfFhCUAhQmpr5x8gdPdPF_VLPEgJr_yGrxrTu7pOIromebkNlu0ndpmuDKwueuqm9kFO8VIkS1jpA-CD4pgxgjjo4S8INMn2N_tH2AN5SMDu3S9NHdIy7adJbE7ACP6eHGOwKgpf6RrrpniDEqosbF8b9MY6GG375gugTUtD-TdGad-iKaib8hhjnaVoACQut0rbA' // Set the "Authorization" header with the token
            }
        };
        try{
            await fetch(`${baseURL}/get-events?event_type=${event_type}` , {
                mode:'cors',
                headers: {
                    'Authorization': 'Bearer 151|UmBb6trcncXKgfzuS4p1cutij4i00UgWm0IhuG1Y',
            
                  
                }
            })
            .then(response => {
                return response.json()
              })
              .then(data => {
                setData(data)
              })
            // console.log("response_district_events", response);
            // console.log('response district events', response.data.data)
            // if (response?.data?.status === true) {
            //     setData(response?.data?.data)
            // }
        }
        catch(err){
            console.log("error district_events",err);
        }
       
    }

    console.log("district_events_data",data);
    // const onLoad = async () => {

    //     const event_type = 'District';
    //     const config = {
    //         headers: {
    //             'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOTNhODNjODQ0MTFmZjQ4YWVmYWEzMDY2Y2E2MmRkMmUyMzQzNTk5YmEwMzBlNGEyYWM3NWMzZDQxNmRhOWUwNWQ2NjVjYzZhYmJmNTEwOTQiLCJpYXQiOjE2ODA1MDAwMjIuODQxMDAyLCJuYmYiOjE2ODA1MDAwMjIuODQxMDA3LCJleHAiOjE3MTIxMjI0MjIuNzI3MjAyLCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.Tr_6B-o4S9YU07ZQKDEl8ib5yFWJ2i-bSI6xQ8b5TkpFlxIL0U1IJ74E5OLtnCRE42YEVNrvMbXp7EU4LQDblEVhPTHBwp3wcIolGtFpYi1p_LhX6MB6uUivNOIdB-jFUo5sH816QUitidqPnBkPb8sUCioPSJm_lziBP7m23MR9X-1hjjIKi_N1k4hNTo717mdCqa6Atjjc1ITN1Pb7Jyptti4dS3KQH695OaFZMdpls0W70OEjpGKGFhbKV-CU4B7cyGJPKldJH85p62HpL5-8OVeQFXugpuoly06PUo2Je1POM785hPXy_HRgLuJufd0jG9_Vm2NPw8UvtzW6VY-wRtUu_0Gvt6Rrupw6p_nm_tipvUJY6I4IfElQMEkLbype1h_Ey6RDI3vxGwOfhK0tZZkVS190gChcQDYrG8wAmZBraOr1Dy1ZBaFDh9fnztAeSdqQMDW07bDcZKRTo9vHWI0l3yLmsX9ijGkfFhCUAhQmpr5x8gdPdPF_VLPEgJr_yGrxrTu7pOIromebkNlu0ndpmuDKwueuqm9kFO8VIkS1jpA-CD4pgxgjjo4S8INMn2N_tH2AN5SMDu3S9NHdIy7adJbE7ACP6eHGOwKgpf6RrrpniDEqosbF8b9MY6GG375gugTUtD-TdGad-iKaib8hhjnaVoACQut0rbA' // Set the "Authorization" header with the token
    //         }
    //     };
    //     const response = await API.get(`/get-events?event_type=${event_type}`, config)

    //     console.log('response district events', response.data.data)
    //     if (response?.data?.status === true) {
    //         setData(response?.data?.data)
    //     }
    // }

    console.log('data', data)

    const all_data = data?.map((item) => item?.event_date);
    console.log("all_data", all_data);


    const currentDate = new Date().toISOString().slice(0, 10);
    const expiry_filteredData = data?.filter((item) => item?.event_date < currentDate);
    const active_upcoming_filteredData = data?.filter((item) => item?.event_date >= currentDate);
    console.log("currentDate", expiry_filteredData, active_upcoming_filteredData);

    useEffect(() => {
        onLoad()
    }, [])
    const assistant = [{
        id: 1,
        zone: 'I',
        name: 'Avishek Dokania',
        club: 'Asansol',
        phoneno: '9434008421',
        emailid: 'avishekdokania@gmail.com'
    }, {
        id: 2,
        zone: 'II',
        name: 'Anuran Lall Singh Handa',
        club: 'Ukhra',
        phoneno: '9932444154',
        emailid: 'anuranlshanda@gmail.com'
    }, {
        id: 3,
        zone: 'III',
        name: 'Santosh Maheshwari',
        club: 'Durgapur Central',
        phoneno: '9832176615',
        emailid: 'santosh.dgp13@yahoo.com'
    },
    {
        id: 4,
        zone: 'IV',
        name: 'Shovan Kumar',
        club: 'Burdwan South',
        phoneno: ['9434177776', '9233333390'],
        emailid: 'shovan.law@gmail.com',
    },
    {
        id: 5,
        zone: 'V',
        name: 'Dr. Shubhankar Ghosh',
        club: 'Tagoreland',
        phoneno: ['9474548535', '7908472575'],
        emailid: 'rumashubhankar@gmail.com',
    },
    {
        id: 6,
        zone: 'VI',
        name: 'Pradip Kr. Sen',
        club: 'Silk City Malda',
        phoneno: '9434323419',
        emailid: 'pksen30@gmail.com',
    },
    {
        id: 7,
        zone: 'VII',
        name: 'Sanchita Bhattacharrya',
        club: 'Raiganj',
        phoneno: '9475105234',
        emailid: 'sanchitapartha@gmail.com',
    },
    {
        id: 8,
        zone: 'VIII',
        name: 'Shiv Shankar Sarkar',
        club: 'Siliguri Metropolitan',
        phoneno: '8697739893',
        emailid: 'Shivshankarsarkar@yahoo.com',
    },
    {
        id: 9,
        zone: 'IX',
        name: 'Gopal Krishna Kayan',
        club: 'Siliguri Uttorayon',
        phoneno: '9932025280',
        emailid: 'gopalkayan@gmail.com',
    },
    {
        id: 10,
        zone: 'X',
        name: 'Jorden Norbhu',
        club: 'Darjeeling',
        phoneno: '9434044218',
        emailid: 'jorden.norbhu@gmail.com',
    },
    {
        id: 11,
        zone: 'XI',
        name: 'Vikas Golyan (Vicky)',
        club: 'Kalimpong',
        phoneno: ['9800193000', '9832093000'],
        emailid: ['golyanvikash@gmail.com', 'vikashgolyan@hotmail.com'],
    },
    {
        id: 12,
        zone: 'XII',
        name: 'Anurag Jain',
        club: 'Gauhati West',
        phoneno: ['9435016657', '9957507328'],
        emailid: 'anuragrotary@gmail.com',
    },
    {
        id: 13,
        zone: 'XIII',
        name: 'Satish Kasera',
        club: 'Guwahati Metro',
        phoneno: '9435019357',
        emailid: 'kaserask@rediffmail.com',
    },
    {
        id: 14,
        zone: 'XIV',
        name: 'Partha Dasgupta',
        club: 'Bongaigaon',
        phoneno: ['8133007222', '9435020678'],
        emailid: 'capitalpartha1@gmail.com',
    },
    {
        id: 15,
        zone: 'XV',
        name: 'Arun Kumar Agarwal',
        club: 'Nagaon',
        phoneno: '9435061476',
        emailid: 'nggarun71@gmail.com',
    },
    {
        id: 16,
        zone: 'XVI',
        name: 'Indira Gogoi',
        club: 'Golaghat',
        phoneno: ['6003149751', '9435515241'],
        emailid: 'indiragogoi.glt@gmail.com',
    },
    {
        id: 17,
        zone: 'XVII',
        name: 'Dr. Nitin Agarwal',
        club: 'Dimapur',
        phoneno: '9402830558',
        emailid: 'docnitinagarwal@gmail.com',
    },
    {
        id: 18,
        zone: 'XVIII',
        name: 'Amit Sharma',
        club: 'Shillong',
        phoneno: '9436103734',
        emailid: 'amitdist3240@gmail.com',
    },
    {
        id: 19,
        zone: 'XIX',
        name: 'Samir Todi',
        club: 'Dibrugarh',
        phoneno: '9435032301',
        emailid: 'samirtodi@gmail.com',
    },
    {
        id: 20,
        zone: 'XX',
        name: 'Arup Kr. Goswami',
        club: 'Digboi',
        phoneno: ['9435337266', '8134030639'],
        emailid: 'arupgoswami15@gmail.com',
    },
    {
        id: 21,
        zone: 'XXI',
        name: 'Dr. Siddhaartha Sankar Bhattacharjee',
        club: 'Silchar',
        phoneno: '9435071196',
        emailid: 'sidharth_dr@yahoo.com',
    },
    {
        id: 22,
        zone: 'XXII',
        name: 'Rafique Ahmed Choudhury',
        club: 'Karimganj',
        phoneno: ['9854058025', '9435075208'],
        emailid: 'rachoudhury1963@gmail.com',
    },
    {
        id: 23,
        zone: 'XXIII',
        name: 'Nirmal Kr. Singhi',
        club: 'Agartala City',
        phoneno: ['9831041277', '9862567021'],
        emailid: 'singhinirmal@yahoo.co.in',
    },
    ]

    return (
        <AnimationRevealPage>
            <SocialIcon />
            <Header roundedHeaderButton={roundedHeaderButton} />
            <Navbar />
            <ContainerBase className="m-5 text-center">
                <div className="mt-3 mb-5">
                    <Heading className="my-5">District Events</Heading>

                    <Sub_Heading className="my-3 w-100 text-start">Upcoming Events</Sub_Heading>
                    <div className="row">
                        {active_upcoming_filteredData?.map((item, index) => {
                            return (
                                <>

                                    <div className="col-xl-4 col-lg-4 col-md-6 mb-4" key={index}>
                                        <div className=" border shadow h-100 card">
                                            <img src={item?.cover_image ? `${imageUrl}${item?.cover_image}` : "../../../../images/placeholderImages.png"} className="card-img-top h-50 border" alt="..." />

                                            <div className="card-body d-flex flex-column justify-content-evenly">
                                                <div className="fs-5 fw-bold">{item?.title}</div>
                                                <div className="row px-4 mt-3">
                                                    <div className="col text-start">

                                                        <div className="fw-bold">Event Type</div>
                                                        <div className="fw-bold">Event Date</div>
                                                        <div className="fw-bold">Event Venue</div>
                                                        <div className="fw-bold">Description</div>

                                                    </div>
                                                    <div className="col-lg-8 text-start fs-6">

                                                        <div>{item?.event_type}</div>
                                                        <div>{item?.event_date}</div>
                                                        <div>{item?.event_venue ? item?.event_venue : ""}</div>
                                                        <div>{item?.description}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>

                    <Sub_Heading className="my-3 w-100 text-start">Past Events</Sub_Heading>
                    <div className="row">
                        {expiry_filteredData?.map((item, index) => {
                            return (
                                <>

                                    <div className="col-xl-4 col-lg-4 col-md-6 mb-4" key={index}>
                                        <div className=" border shadow h-100 card">
                                            <img src={item?.cover_image ? `${imageUrl}${item?.cover_image}` : "../../../../images/placeholderImages.png"} className="card-img-top h-50 border" alt="..." />

                                            <div className="card-body d-flex flex-column justify-content-evenly">
                                                <div className="fs-5 fw-bold">{item?.title}</div>
                                                <div className="row px-4 mt-3">
                                                    <div className="col text-start">

                                                        <div className="fw-bold">Event Type</div>
                                                        <div className="fw-bold">Event Date</div>
                                                        <div className="fw-bold">Event Venue</div>
                                                        <div className="fw-bold">Description</div>

                                                    </div>
                                                    <div className="col-lg-8 text-start fs-6">

                                                        <div>{item?.event_type}</div>
                                                        <div>{item?.event_date}</div>
                                                        <div>{item?.event_venue ? item?.event_venue : ""}</div>
                                                        <div>{item?.description}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </ContainerBase>
            <Footer />
        </AnimationRevealPage>
    )
}



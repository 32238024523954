import React, { useRef } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
// import { SectionHeading } from "components/misc/Headings";
import SocialIcon from "components/SocialIcon";
// import Hotel from "./Hotel";
import "./PaymentSuccess.css";
import ReactToPrint from 'react-to-print';
import { useSelector } from "react-redux";

// const HeadingRow = tw.div`flex`;
// const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Payment Success" }) => {

    const componentRef = useRef();
    const hotel = useSelector(state=>state.hotel)

  return (
    <AnimationRevealPage>
      <SocialIcon />
      <Header />
      <Container ref={componentRef}>
        <ContentWithPaddingXl>
          {/* <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow> */}
          <Text>
            <div className="row payment-container" >
                <div className="col-lg-12 header">
                    <img className="tick-success" src="images/hotels/green-logo-circle-tick.png" alt="success tick" /> 
                    <p><string>{headingText}!</string></p>
                    <hr />
                </div>
                <div className="col-lg-12 body">
                    <div className="details">
                        <p>References Number</p>
                        <p>000085752257</p>
                    </div>
                    <div className="details">
                        <p>Date</p>
                        <p>Mar 22, 2023</p>
                    </div>
                    <div className="details">
                        <p>Time</p>
                        <p>07:80 AM</p>
                    </div>
                    <div className="details">
                        <p>Payment Method</p>
                        <p>Credit Card</p>
                    </div>
                    <hr/>
                    <div className="details">
                        <p>Amount</p>
                        <p>1000</p>
                    </div>
                </div>

                <div className="col-lg-12 body">
                    <div className="details">
                        <p>Member Name</p>
                        <p>{hotel.memberName}</p>
                    </div>
                    <div className="details">
                        <p>Phone Number</p>
                        <p>{hotel.phoneNumber}3</p>
                    </div>
                    <div className="details">
                        <p>Email Address</p>
                        <p>{hotel.emailAddress}</p>
                    </div>
                    <div className="details">
                        <p>Club Name</p>
                        <p>{hotel.clubName}</p>
                    </div>
                    <hr/>
                    <div className="details">
                        <p>Selected Rooms</p>
                        <p>{hotel.selectedRooms}</p>
                    </div>
                </div>
                <div className="col-lg-12 pdf-section">
                    <ReactToPrint
                        trigger={() => <button className="btn btn-outline-primary btn-block">Get PDF Receipt</button>}
                        content={() => componentRef.current}
                    />
                </div>
            </div>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

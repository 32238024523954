/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Playstore from "components/playstore";
import Districtleadercarousel from "components/district/districtleadercarousel";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;

  return (
    <AnimationRevealPage>
      
      <Hero roundedHeaderButton={true} />

      <Districtleadercarousel/>
      <FeatureWithSteps
       
        heading={
          <>
           
            RI THEME VIDEO 2023-24
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      
      <Pricing
        // subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            JOIN US
          </>
        }
        
      />
      <Testimonial
        subheading={<Subheading>OUR MOTTO</Subheading>}
        heading={
          <>
             "SERVICE ABOVE SELF"
          </>
        }
        
      />
      
      <GetStarted/>
      <Playstore/>
      <Footer />
    </AnimationRevealPage>
  );
}

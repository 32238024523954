
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header, { NavLinks } from "../headers/light.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import '../../style.css'
import Navbar from "components/Navbar.js";
import { NavLink } from "react-router-dom";
import Enquiry_Form from "components/Enquiry_Form.js";
import SocialIcon from "components/SocialIcon.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;

const LeftColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const RightColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;


const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;



const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;





export default ({ roundedHeaderButton }) => {

  const [enquiryform, setEnquiryform] = useState(false);

  return (
    <>

      <SocialIcon/>
      <Header roundedHeaderButton={roundedHeaderButton} />


      <Navbar />

      <nav className="navbar navbar-expand-lg py-2 flex text-center w-full justify-between" style={{ padding: '10px 10px 10px 20px', backgroundColor: 'aliceblue' }}>

        <h3 className="" style={{ marginRight: 'auto', marginLeft: 'auto', fontWeight: 'bold' }}>Notifications</h3>
        <marquee width="80%" direction="left" height="auto">
          No Notification found
        </marquee>

      </nav>



      <Container>
        <TwoColumn className="">

          <LeftColumn style={{ marginRight: '20px' }} className="">
            <IllustrationContainer>

              <div id="carouselDarkVariant" className="carousel slide carousel-fade carousel-dark relative" data-bs-ride="carousel" style={{ padding: "20px" }}>


                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div className="carousel-inner w-full overflow-hidden" style={{ verticalAlign: 'middle' }}>

                    <div className="carousel-item active relative float-left w-full">
                      <img src="../../../../images/rotary1.PNG" className="block w-full rounded-lg" alt="Motorbike Smoke" />
                      <div className="carousel-caption hidden  text-center">
                      </div>
                    </div>
                    <div className="carousel-item relative float-left w-full">
                      <img src="../../../../images/rotary2.PNG" className="block w-full rounded-lg" alt="Mountaintop" />
                      <div className="carousel-caption hidden  text-center">
                      </div>
                    </div>
                    <div className="carousel-item relative float-left w-full">
                      <img src="../../../../images/rotary3.PNG" className="block w-full rounded-lg" alt="Woman Reading a Book" />
                      <div className="carousel-caption hidden  text-center">

                      </div>
                    </div>
                    <div className="carousel-item relative float-left w-full">
                      <img src="../../../../images/rotary4.PNG" className="block w-full rounded-lg" alt="Woman Reading a Book" />
                      <div className="carousel-caption hidden  text-center">
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center', padding: '25px' }}>
                    <button

                      className="rounded-full flex "
                      type="button"
                      data-bs-target="#carouselDarkVariant"
                      data-bs-slide="prev"
                      style={{ top: 'auto', backgroundColor: 'rgb(12, 63, 116)', marginLeft: '20px', padding: '20px' }}
                    >

                      <svg xmlns="https://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" style={{ color: 'white' }}>
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                      </svg>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <div className="vl" style={{ left: '50%', top: '0', margin: '0 15px', borderLeft: '6px solid', borderImage: 'grey' }}></div>
                    <button
                      className="rounded-full flex"
                      type="button"
                      data-bs-target="#carouselDarkVariant"
                      data-bs-slide="next"
                      style={{ top: 'auto', backgroundColor: 'rgb(12, 63, 116)', marginRight: '20px', padding: '20px' }}
                    >

                      <svg xmlns="https://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16" style={{ color: 'white' }}>
                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                      </svg>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
              {/* <div id="carouselExampleControls" className="carousel slide border" data-bs-ride="carousel " style={{}}>
                <div className="carousel-inner mx-auto">
                    {carouselphoto?.map((item, index) =>
                        <div key={index} className={item?.status ? "carousel-item active px-3 " : "px-3 carousel-item "}>
                            <img src={item} className="d-block w-100 " style={{height:400}}/>
                            
                        </div>
                    )}

                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon rounded-pill " aria-hidden="true" style={{backgroundColor:'#000'}}></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon rounded-pill" aria-hidden="true" style={{backgroundColor:'#000'}}></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div> */}

              {/* <TestimonialSlider style={{ width: '40%' }} className="m-5">
            {testimonials.map((testimonial, index) => (
              <img src={testimonial?.profileImageSrc} />

            ))}
          </TestimonialSlider> */}


            </IllustrationContainer>

          </LeftColumn>


          <RightColumn style={{ textAlign: 'center' }} className="">

            <Heading>
              ROTARY INTERNATIONAL DISTRICT 3240
            </Heading>

            <Paragraph style={{ textAlign: 'left' }}>
              Rotary International District 3240 lies in the North Eastern part of the Indian subcontinent between Nepal, China, Bhutan, Myanmar and Bangladesh. This district is one of the largest Rotary districts in the world covering over 2,95, 607 sq. kms of land. Closeted within these perimeters is a land of snow clad mountains, verdant hills, expansive plains, mountain streams, majestic rivers, bustling towns, idyllic hamlets and miles and miles of land carpeted by lush green tea bushes. R.I. District 3240 is a relatively new district formed in the Year 1990 and encompasses nine states of India – Arunachal Pradesh, Assam, Manipur, Meghalaya, Mizoram, Nagaland, Sikkim, Tripura and eight revenue districts of West Bengal. (Barddhaman, Birbhum, Malda, Dakshin Dinajpur, Uttar Dinajpur, Darjeeling, Jalpaiguri & Cooch Bihar). There are 97 clubs in the district with 3342 Rotarians.
            </Paragraph>

            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ backgroundColor: 'rgb(12, 63, 116)', color: 'white', padding: '15px 35px', borderRadius: '15px' }}>
              Get Involved
            </button>


            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                {/* <div className="modal-content"> */}
                  <Enquiry_Form />
                {/* </div> */}
              </div>
            </div>
          </RightColumn>
        </TwoColumn>

        <DecoratorBlob1 />

      </Container>
    </>
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import { Heading } from "components/misc/Headings";
import Header, { NavLinks } from "../../components/headers/light";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Navbar from '../../components/Navbar';
import SocialIcon from "components/SocialIcon";



export const Projects = ({
    roundedHeaderButton = true
}
) => {


    return (
        <AnimationRevealPage>
             <SocialIcon/>
            <Header roundedHeaderButton={roundedHeaderButton} />
            <Navbar />
            <ContainerBase className="m-5 text-center">
                <Heading>Coming Soon....</Heading>
            </ContainerBase>
            <Footer />
        </AnimationRevealPage>
    )
}



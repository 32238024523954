import React, { useState, useEffect } from "react";
import { components } from "ComponentRenderer.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl, ContentWithVerticalPadding } from "components/misc/Layouts";

import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
import Offer from "components/offer/Offer";

export default ({
  features = null,
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  blocks = components.blocks,
}) => {
  useEffect(() => {
    window.gtag("js", new Date());
    window.gtag("config", "UA-45799926-9");
  }, [])

  const noOfLandingPages = Object.keys(landingPages).length;
  const noOfInnerPages = Object.keys(innerPages).length;
  const noOfComponentBlocks = Object.values(blocks).reduce((acc, block) => acc + Object.keys(block.elements).length, 0);

  features = features || [
    `${noOfLandingPages} Landing Page Demos`,
    `${noOfInnerPages} Inner Pages`,
    `${noOfComponentBlocks} Components`,
    "Uses TailwindCSS",
    "Fully Responsive",
    "Fully Customizable"
  ];

  return (
    <AnimationRevealPage 
    disabled 
    >
      <Container tw="bg-gray-100 -mx-8 -mt-8 px-8">
        <Content2Xl style={{marginLeft:0,marginRight:0,maxWidth:'none'}}>
          <SaaSProductLandingPage/>
        </Content2Xl>
        <Offer/>
      </Container>
    </AnimationRevealPage>
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeading } from "components/misc/Headings";
import SocialIcon from "components/SocialIcon";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Cancellation Policy" }) => {
  return (
    <AnimationRevealPage>
      <SocialIcon />
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>We understand that circumstances can change, and you may need to reconsider your booking. However, please note that our e-commerce website operates with a strict no-cancellation policy. Once a booking order has been successfully placed and confirmed, it cannot be cancelled or modified. We encourage you to carefully review your booking details before completing the booking.</p><br/>

            <p>If you encounter any issues with your booking, please reach out to our Confero Accommodation Team at <span style={{color:'blue'}}>hotels@confero3240.in / 9957181036</span>. They will be more than happy to assist you and address any concerns you may have.</p><br/>

            <p>Thank you for choosing to book with us. We appreciate your understanding of our no-cancellation policy and look forward to serving you.</p><br/>

            <p>www.rid3240.org</p>
            <p>11-Aug-2023</p>
            
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

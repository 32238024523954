import "slick-carousel/slick/slick.css";
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import loveIllustrationImageSrc from "images/love-illustration.svg";
import { ReactComponent as StarIconBase } from "images/star-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";
import President_Profile from '../district/2_R I President/R I President.pdf';
import Governor_Profile from '../district/1_District Governor/DG_Profile.pdf';
import President_InstallationLetter from '../district/2_R I President/RI Preisdent_Club Installation Letter - D3240.pdf'
import Governor_Message from '../district/1_District Governor/DG_Message.pdf';

// const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 xl:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:pr-12 lg:pr-16 md:order-first` : tw`md:pl-12 lg:pl-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-6 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed `;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-10 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Testimonial = tw.div`outline-none h-full flex! flex-col`;
const StarsContainer = styled.div``;
const StarIcon = tw(StarIconBase)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;
const TestimonialHeading = tw.div`mt-4 text-xl font-bold`;
const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed font-medium text-gray-700`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-between items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
// const CustomerProfilePicture = tw.img`rounded-lg w-16 h-16 sm:w-20 sm:h-20`;
// const CustomerProfilePicture = tw.img`rounded-lg w-16 h-16 sm:w-80 sm:h-80`;
// const CustomerProfilePicture = tw.img`rounded-lg w-16 h-16 sm:w-20 sm:h-20`;
const CustomerProfilePicture = tw.img`rounded-lg w-16 h-16 sm:w-80 sm:h-80`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-bold text-xl`;
// const CustomerTitle = tw.p`font-medium text-secondary-100 text-end`;
const CustomerTitle = tw.p`font-medium text-blue-800 text-end`;

// const Controls = styled.div`
//   ${tw`flex mt-8 sm:mt-0`}
//   .divider {
//     ${tw`my-3 border-r`}
//   }
// `;
const Controls = styled.div`
  ${tw`flex  sm:mt-0`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
// const ControlButton = styled.button`
//   ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-primary-500 hover:text-primary-700 focus:outline-none focus:shadow-outline`}
//   svg {
//     ${tw`w-4 h-4 stroke-3`}
//   }
// `;
const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-primary-500 hover:text-primary-700 focus:outline-none focus:shadow-outline`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;

export default ({
  imageSrc = loveIllustrationImageSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  subheading = "Testimonials",
  heading = "Our Clients Love Us.",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.",
  textOnLeft = false,
  testimonials = [
    {
      id: 0,
      stars: 5,
      profileImageSrc:
        // "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
        // "../../../../images/p1.PNG",
        // "../../../../images/RI_President.png",
        "../../../../images/RI_President.png",
      heading: "ROTARY INTERNATIONAL PRESIDENT",
      quote:
        "Gordon R. McInally, a member of the Rotary Club of South Queensferry, Lothian, Scotland, is the Rotary International President for the Rotary year 2023-24.McInally lauded Rotary’s ability to adapt technologically during the COVID-19 pandemic, saying the approach should continue and be combined with the best of our past practices as Rotary seeks to grow and increase engagement.“We have learned there is a willingness within communities to care for one another,” he says, “and we must ensure that we encourage people who have recently embraced the concept of volunteering to join us to allow them to continue giving service.”McInally says that senior leaders’ ability to communicate directly with club members online will be one positive legacy of the changes Rotary has had to make. But, he adds, “face-to-face meetings remain important, as they encourage greater interaction.",
      customerName: "Gordon R. McInally",
      // customerTitle: "ROTARY INTERNATIONAL PRESIDENT",
      customerTitle: "Profile",
      // profile:'../district/2_R I President/R I President.pdf',
      profile: President_Profile,
      message: President_InstallationLetter,
      customerTitle1: "Installation Letter"
    },
    {
      id: 1,
      stars: 5,
      profileImageSrc:
        // "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
        // "../../../../images/p2.PNG",
        "../../../../images/DI_Governor.jpeg",
      heading: "DISTRICT GOVERNOR",
      quote:
        // "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        "Rotary International District 3240 lies in the North Eastern part of the Indian subcontinent between Nepal, China, Bhutan, Myanmar and Bangladesh. This district is one of the largest Rotary districts in the world covering over 2,95, 607 sq. kms of land. Closeted within these perimeters is a land of snow clad mountains, verdant hills, expansive plains, mountain streams, majestic rivers, bustling towns, idyllic hamlets and miles and miles of land carpeted by lush green tea bushes. R.I. District 3240 is a relatively new district formed in the Year 1990 and encompasses nine states of India – Arunachal Pradesh, Assam, Manipur, Meghalaya, Mizoram, Nagaland, Sikkim, Tripura and eight revenue districts of West Bengal. (Barddhaman, Birbhum, Malda, Dakshin Dinajpur, Uttar Dinajpur, Darjeeling, Jalpaiguri & Cooch Bihar). There are 97 clubs in the district with 3342 Rotarians.",
      customerName: "Nilesh Kumar Agarwal",
      // customerTitle: "DISTRICT GOVERNOR"
      customerTitle: "Profile",
      customerTitle1: "Message",
      profile: Governor_Profile,
      message: Governor_Message,
    }
  ]
}) => {
  const [sliderRef, setSliderRef] = useState(null);
  const profile = (testimonial, index) => {
    console.log('testimonial', testimonial.profile, index)

  }
  const handleLinkClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Container style={{ backgroundImage: "url('https://i.pinimg.com/originals/22/e2/2a/22e22a1e45bef25f8079823ca773a262.jpg')" }}>
      <ContentWithPaddingXl style={{ padding: '20px 0' }}>
        {/* <Row> */}
        {/* <ImageColumn>
            <Image src="" imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
          </ImageColumn> */}
        <TextColumn textOnLeft={textOnLeft} style={{ width: '100%', paddingTop: '0' }}>
          {/* <Subheading>OUR MOTTO</Subheading> */}
          <Heading style={{ fontSize: '2rem', marginRight: '', textAlign: 'center' }}>RI President 2023-24</Heading>

          <TestimonialSlider arrows={false} ref={setSliderRef}>
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index}>

                <CustomerInfoAndControlsContainer>
                  <CustomerInfo >
                    <CustomerTextInfo style={{width:'30%'}}>
                      <CustomerProfilePicture src={testimonial.profileImageSrc} alt={testimonial.customerName} className="w-100 " />

                    </CustomerTextInfo>
                    {/* <CustomerTextInfo style={{ width: '-webkit-fill-available' }}> */}
                    <CustomerTextInfo style={{width:'100%'}}>
                      <CustomerName>{testimonial.customerName}</CustomerName>
                      {/* <CustomerTitle>{testimonial.customerTitle}</CustomerTitle> */}
                      <TestimonialHeading>{testimonial.heading}</TestimonialHeading>
                      <Quote>{testimonial.quote}</Quote>
                      {/* <div className="d-flex justify-content-end align-items-center">
                        <button>
                          <CustomerTitle className="ms-4" onClick={() => handleLinkClick(testimonial?.profile)}>
                          <i className="bi bi-person-circle me-2"></i> {testimonial?.customerTitle}
                          </CustomerTitle>
                        </button>
                        <button>
                          <CustomerTitle className="ms-4" onClick={() => handleLinkClick(testimonial?.message)}>
                          <i className="bi bi-envelope-fill me-2"></i>{testimonial?.customerTitle1}
                          </CustomerTitle>
                        </button>
                      </div> */}
                    </CustomerTextInfo>

                  </CustomerInfo>

                </CustomerInfoAndControlsContainer>

              </Testimonial>))}

          </TestimonialSlider>

          <Controls style={{}}>
            <ControlButton onClick={sliderRef?.slickPrev}>
              <ArrowLeftIcon style={{ color: 'rgb(12, 63, 116)' }} />
            </ControlButton>
            <div className="divider" />
            <ControlButton onClick={sliderRef?.slickNext}>
              <ArrowRightIcon style={{ color: 'rgb(12, 63, 116)' }} />
            </ControlButton>
          </Controls>

        </TextColumn>

        {/* </Row> */}
      </ContentWithPaddingXl>
    </Container>
  );
};

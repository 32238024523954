import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { Heading, Subheading } from "components/misc/Headings";
import Header, { NavLinks } from "../../components/headers/light";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Hero from '../../components/hero/TwoColumnWithInput';
import Navbar from '../../components/Navbar';
import Enquiry_Form from "components/Enquiry_Form";
import SocialIcon from "components/SocialIcon";



export const Contact_Us = ({


    roundedHeaderButton = true

}
) => {


    return (
        <AnimationRevealPage>
             <SocialIcon/>
            <Header roundedHeaderButton={roundedHeaderButton} />
            <Navbar />
            <ContainerBase className="m-5 text-center">
                {/* <Heading className="my-5">RID3240 WEBSITE FEEDBACK</Heading> */}
                <Heading className="my-5">Contact Us</Heading>
            {/* <Subheading className="mb-5">WEBSITE VISITORS ARE REQUESTED TO FILL UP THE FOLLOWING FORM TO SEND US ANY COMMENTS, SUGGESTIONS WHICH WILL HELP US IMPROVE THE WEBSITE.</Subheading> */}
                <div className="row text-left w-75 mx-auto align-items-center " >
                    
                    <div className="col-lg-6 col-md-12">
                        <div className="h-100 my-auto">
                            <div className="col h-50 ">
                                <hr className="my-4" />

                                <Heading>Dr. Danny Dutta</Heading>
                                <div className="d-flex flex-column m-0 h-100  justify-content-evenly">

                                    <a className="d-flex align-items-center h-100 mt-3" href="#" style={{ marginRight: '25px' }}>
                                        <i className="fas fa-envelope" style={{ marginRight: '5px' }}>
                                        </i>ramesh3240des2324@gmail.com</a>

                                    <a className="d-flex align-items-center h-100 mt-3" href="#">
                                        <svg xmlns="https://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16" style={{ verticalAlign: 'middle', marginRight: '5px' }}>
                                            <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                        </svg>
                                        +91-9207081564
                                    </a>

                                </div>
                            </div>
                            <hr className="mb-5 mt-5" />
                            <div className="col h-50">
                                
                                <Heading>Mr. Abhimanyu Saraf</Heading>
                                <div className="d-flex flex-column m-0 h-100 justify-content-evenly">
                                    <a className="d-flex align-items-center h-100 mt-3" href="#" style={{ marginRight: '25px' }}>
                                        <i className="fas fa-envelope" style={{ marginRight: '5px' }}>
                                        </i> saraf.abhimanyu@gmail.com</a>

                                    <a className="d-flex align-items-center h-100 mt-3  " href="#">
                                        <svg xmlns="https://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16" style={{ verticalAlign: 'middle', marginRight: '5px' }}>
                                            <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                        </svg>
                                        +91-6901224931
                                    </a>
                                </div>
                                <hr className="my-4" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <Enquiry_Form />
                    </div>
                </div>

            </ContainerBase>
            <Footer />
        </AnimationRevealPage>
    )
}



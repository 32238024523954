import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeading } from "components/misc/Headings";
import SocialIcon from "components/SocialIcon";
import Hotel from "./Hotel";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { removeTotalPrice } from "store/features/hotelSlice";
import { API } from "generalfunction";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Booking" }) => {

  const [ hotels, setHotels ] = useState([])
  const [ isLoading, setLoading ] = useState(false)
  const dispatch = useDispatch()

  const fetchHotels = async () => {
    try{
      const response = await API.get("/get-hotels")
      const jsonData = response.data.data
      setHotels(jsonData)
    }catch(error){
      console.log({error});
      console.log(`hotel fetch error: ${error}`);
    }
  }

  useEffect(()=>{
    setLoading(true)
    fetchHotels()
    setLoading(false)
    dispatch(removeTotalPrice())
  },[dispatch])

  return (
    <AnimationRevealPage>
      <SocialIcon />
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <img src="/images/hotels/booking.jpg" alt="booking" width='100%'/>
          </Text>
          {
            isLoading ? <h2>Loading...</h2> : hotels.map((hotel,i)=>{
              return <Hotel hotel={hotel} hotelId={i} totalHotels={hotels.length} key={i}/>
            })
          }
          {/* { hotels && } */}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

import React, { useState } from 'react';
import { LoginLink } from './misc/Links';
import { Heading } from './misc/Headings';
import axios from 'axios';

const Enquiry_Form = () => {
    const [name, setName] = useState('');
    const [phoneno, setPhoneno] = useState();
    const [location, setLocation] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
   
    const handleSubmit = (e) => {
        e.preventDefault();
        const emailContent = `Name: ${name}\nEmail: ${email}\nPhone Number: ${phoneno}\nLocation: ${location}\nMessage: ${message}`;
        const mailtoUrl = `mailto:saraf.abhimanyu@gmail.com?subject=New Message&body=${encodeURIComponent(emailContent)}`;
        window.location.href = mailtoUrl;
    };

   
    
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const numberPattern = /^\d*$/; // Regular expression to match numbers only

    if (numberPattern.test(inputValue)) {
      setPhoneno(inputValue);
    }
  };
    return (
        <div className="modal-body">
            <form onSubmit={handleSubmit} className=' w-100 text-left mx-auto' style={{ backgroundColor: '#fff', boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                <div className='px-5 py-4'>
                    <Heading className='text-center mb-3'>Enquiry Form</Heading>
                    {/* <div className=''> */}
                    <div className="mb-3 text-sm mb-3">
                        <label htmlFor="formGroupExampleInput" className=" text-sm form-label">Full Name</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control text-sm"
                            id="formGroupExampleInput"
                            placeholder="Enter your full name"
                            required
                        />
                    </div>
                    <div className=" text-sm mb-3">
                        <label htmlFor="formGroupExampleInput2" className=" text-sm form-label">Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control text-sm"
                            id="formGroupExampleInput2"
                            placeholder="Enter your email address" />
                    </div>
                    <div className=" text-sm mb-3">
                        <label htmlFor="formGroupExampleInput3" className=" text-sm form-label">Phone No.</label>
                        <input
                            type="text"
                            value={phoneno}
                            // onClick={(e) => setPhoneno(e.target.value)}
                            onChange={handleInputChange}
                            className="form-control text-sm"
                            id="formGroupExampleInput3"
                            placeholder="Enter your phone no."
                            required
                        />
                    </div>
                    <div className=" text-sm mb-3">
                        <label htmlFor="formGroupExampleInput4" className=" text-sm form-label">Location</label>
                        <input
                            type="text"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            className="form-control text-sm"
                            id="formGroupExampleInput4"
                            placeholder="Enter your current location"
                            required
                        />
                    </div>
                    <div className=" text-sm mb-3">
                        <label htmlFor="formGroupExampleInput5" className=" text-sm form-label">Message Box</label>
                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="form-control text-sm"
                            id="formGroupExampleInput5"
                            placeholder="Describe your Interest" />
                    </div>
                    {/* </div> */}
                    <div className='w-100 text-right mt-4'>
                        {/* <LoginLink type="submit">Submit</LoginLink> */}
                        <button 
                        type="submit"
                        className='px-3 py-2 rounded-pill text-white'
                        style={{backgroundColor:'#108910'}}
                        >Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Enquiry_Form
